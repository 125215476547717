import Home from "./views/Home.vue";
import { NotFound, NotAuthorized } from "@modernary/mui";
// import loginRoutes from "./views/login/loginRoutes";
import { default as clientRoutes, clientRouteNames } from "./views/client/clientRoutes";
import { default as opportunityRoutes, opportunityRouteNames } from "./views/opportunity/opportunityRoutes";
import { default as projectRoutes, projectRouteNames } from "./views/project/projectRoutes";

// Troubleshoot your routes using this - https://paths.esm.dev

export const routeNames = {
  home: "Home",
  notAuthorized: "NotAuthorized",
  ...clientRouteNames,
  ...opportunityRouteNames,
  ...projectRouteNames,
};

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: "/", component: Home, name: routeNames.home, meta: { title: "Home" } },
  {
    path: "/unauthorized",
    component: NotAuthorized,
    name: routeNames.notAuthorized,
    meta: { title: "Not Authorized" },
  },
  ...clientRoutes,
  ...opportunityRoutes,
  ...projectRoutes,
  // ...loginRoutes,
  { path: "/:path(.*)", component: NotFound },
];
