import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityNotes } from "@modernary/mui";

const names = {
  opportunities: "Opportunities",
  opportunityList: "OpportunityList",
  opportunityNew: "OpportunityNew",
  opportunityEdit: "OpportunityEdit",
  opportunityBase: "OpportunityBase",
  opportunityView: "OpportunityView",
  opportunityNotes: "OpportunityNotes",
  opportunityNoteEdit: "OpportunityNoteEdit",
  opportunityNoteNew: "OpportunityNoteNew",
  opportunityAudit: "OpportunityAudit",
};

export { names as opportunityRouteNames };

const opportunityRoutes = [
  {
    path: "/opportunities",
    name: names.opportunities,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.opportunityList },
    children: [
      {
        path: "list",
        name: names.opportunityList,
        meta: { title: "Opportunities" },
        component: () => import("@/views/opportunity/OpportunityList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/opportunity/OpportunityBase.vue"),
        redirect: { name: names.opportunityNew },
        props: true,
        children: [
          {
            path: "",
            name: names.opportunityNew,
            meta: { title: "New Opportunity", isNew: true },
            component: () => import("@/views/opportunity/OpportunityEdit.vue"),
          },
          {
            path: ":clientId(\\d+)?",
            name: names.opportunityNew,
            meta: { title: "New Opportunity", isNew: true },
            component: () => import("@/views/opportunity/OpportunityEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.opportunityBase,
        component: () => import("@/views/opportunity/OpportunityBase.vue"),
        redirect: { name: names.opportunityView },
        children: [
          {
            path: "view",
            name: names.opportunityView,
            meta: { title: "Opportunity View" },
            component: () => import("@/views/opportunity/OpportunityView.vue"),
          },
          {
            path: "edit",
            name: names.opportunityEdit,
            meta: { title: "Opportunity Edit", isForm: true },
            component: () => import("@/views/opportunity/OpportunityEdit.vue"),
          },
          {
            path: "notes",
            name: names.opportunityNotes,
            meta: { title: "Opportunity Internal Notes" },
            component: {
              render: () => <EntityNotes idField="opportunityId" routeNamePrefix="OpportunityNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.opportunityNoteEdit,
            meta: { title: "Edit Opportunity Internal Note", isForm: true },
            component: () => import("@/views/opportunity/OpportunityNote.vue"),
          },
          {
            path: "notes/new",
            name: names.opportunityNoteNew,
            meta: { title: "Create Opportunity Internal Note", isForm: true },
            component: () => import("@/views/opportunity/OpportunityNote.vue"),
          },
          {
            path: "activity",
            name: names.opportunityAudit,
            meta: { title: "Opportunity Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Opportunity" />,
            },
          },
        ],
      },
    ],
  },
];
export default opportunityRoutes;
