<template>
  <router-view />
  <BaseNotifications></BaseNotifications>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BaseNotifications, useAppNav } from "@modernary/mui";
import { routeNames } from "@/routes";

const route = useRoute();
const { setNavigationItems } = useAppNav();

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  { label: "Home", name: routeNames.home },
  { label: "Clients", name: routeNames.clients },
  { label: "Opportunities", name: routeNames.opportunities },
  { label: "Projects", name: routeNames.projects },
]);
</script>
