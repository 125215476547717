import { IProject } from "@/views/project/project";
import { addressFromFormkit, addressToFormkit } from "@modernary/mui";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
};

function addAuthRequestFields(entity: IProject) {
  entity._authRequestFields = ["id"];
  return entity;
}
function getInitialData() {
  const result = {} as IProject; //TODO: ts
  return addAuthRequestFields(result);
}

function responseAdapter(response: { data: IProject }) {
  addAuthRequestFields(response.data);
  return response.data;
}

function requestAdapter(data: IProject) {
  return data;
}
