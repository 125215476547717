import projectService from "@/views/project/projectService";
import {
  IPaging,
  IPagingApiAndNotifierConfig,
  NotifyOptionsError,
  NotifyOptionsSuccess,
  apiService,
  serializeQueryParams,
} from "@modernary/mui";
import { apiHosts } from "@/api/constants/apiHosts";
import { IProject, IProjectNew } from "@/views/project/project";
import type { AxiosResponse } from "axios";
import { getClientById } from "../client/clientApi";

const URL = "projects";
const ENTITY_NAME = "Project";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IProject, IPaging<IProject>>(apiHosts.Kauai, axiosParams);

/**
 * @param {Object} params Query parameters
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getProjects = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getProjectById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewProject = async (ids: any) => {
  //optionally make any other changes as necessary before returning.
  const defaultApp = {
    data: {
      ...projectService.getInitialData(),
      ...ids,
    },
  } as AxiosResponse<IProjectNew, any>;

  if (ids.clientId) {
    const client = ids.clientId && getClientById(ids.clientId);

    return Promise.all([client]).then(([clientData]) => {
      defaultApp.data.client = clientData?.data;

      return defaultApp;
    });
  } else {
    return defaultApp;
  }
};

const createProject = (project: IProject, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, project, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateProject = (project: IProject, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + project.id, project, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Project} project This cannot be a 'ref' to a project
 */
const saveProject = (project: IProject, config: IPagingApiAndNotifierConfig = {}) => {
  if (project.id) {
    return updateProject(project, config);
  } else {
    return createProject(project, config);
  }
};

/**
 * @param {Int} id
 */
const deleteProjectById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { deleteProjectById, getProjects, getProjectById, getNewProject, createProject, updateProject, saveProject };
