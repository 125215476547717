import clientService from "@/views/client/clientService";
import {
  IPaging,
  IPagingApiAndNotifierConfig,
  NotifyOptionsError,
  NotifyOptionsSuccess,
  apiService,
  serializeQueryParams,
} from "@modernary/mui";
import { apiHosts } from "@/api/constants/apiHosts";
import { IClient, IClientNew } from "@/views/client/client";
import type { AxiosResponse } from "axios";

const URL = "clients";
const ENTITY_NAME = "Client";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IClient, IPaging<IClient>>(apiHosts.Kauai, axiosParams);

/**
 * @param {Object} params Query parameters
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getClients = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getClientById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewClient = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: clientService.getInitialData() } as AxiosResponse<IClientNew, any>;
};

const createClient = (client: IClient, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, client, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateClient = (client: IClient, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + client.id, client, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Client} client This cannot be a 'ref' to a client
 */
const saveClient = (client: IClient, config: IPagingApiAndNotifierConfig = {}) => {
  if (client.id) {
    return updateClient(client, config);
  } else {
    return createClient(client, config);
  }
};

/**
 * @param {Int} id
 */
const deleteClientById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { deleteClientById, getClients, getClientById, getNewClient, createClient, updateClient, saveClient };
