import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";

const names = {
  clients: "Clients",
  clientList: "ClientList",
  clientNew: "ClientNew",
  clientBase: "ClientBase",
  clientView: "ClientView",
  clientOpportunities: "ClientOpportunities",
  clientProjects: "ClientProjects",
  clientAudit: "ClientAudit",
};

export { names as clientRouteNames };

const clientRoutes = [
  {
    path: "/clients",
    name: names.clients,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.clientList },
    children: [
      {
        path: "list",
        name: names.clientList,
        meta: { title: "Clients" },
        component: () => import("@/views/client/ClientList.vue"),
      },
      {
        path: ":id(\\d+)",
        name: names.clientBase,
        component: () => import("@/views/client/ClientBase.vue"),
        redirect: { name: names.clientView },
        children: [
          {
            path: "view",
            name: names.clientView,
            meta: { title: "Client View" },
            component: () => import("@/views/client/ClientView.vue"),
          },
          {
            path: "opportunities",
            name: names.clientOpportunities,
            meta: { title: "Client Opportunities" },
            component: () => import("@/views/client/OpportunitiesChildList.vue"),
          },
          {
            path: "projects",
            name: names.clientProjects,
            meta: { title: "Client Projects" },
            component: () => import("@/views/client/ProjectsChildList.vue"),
          },
          {
            path: "activity",
            name: names.clientAudit,
            meta: { title: "Client Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Client" />,
            },
          },
        ],
      },
    ],
  },
];
export default clientRoutes;
