import opportunityService from "@/views/opportunity/opportunityService";
import {
  IPaging,
  IPagingApiAndNotifierConfig,
  NotifyOptionsError,
  NotifyOptionsSuccess,
  apiService,
  serializeQueryParams,
} from "@modernary/mui";
import { apiHosts } from "@/api/constants/apiHosts";
import { IOpportunity, IOpportunityNew } from "@/views/opportunity/opportunity";
import type { AxiosResponse } from "axios";
import { getClientById } from "../client/clientApi";

const URL = "opportunities";
const ENTITY_NAME = "Opportunity";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IOpportunity, IPaging<IOpportunity>>(apiHosts.Kauai, axiosParams);

/**
 * @param {Object} params Query parameters
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getOpportunities = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getOpportunityById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewOpportunity = async (ids: any) => {
  //optionally make any other changes as necessary before returning.
  const defaultApp = {
    data: {
      ...opportunityService.getInitialData(),
      ...ids,
    },
  } as AxiosResponse<IOpportunityNew, any>;

  if (ids.clientId) {
    const client = ids.clientId && getClientById(ids.clientId);

    return Promise.all([client]).then(([clientData]) => {
      defaultApp.data.client = clientData?.data;

      return defaultApp;
    });
  } else {
    return defaultApp;
  }
};

const createOpportunity = (opportunity: IOpportunity, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, opportunity, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateOpportunity = (opportunity: IOpportunity, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + opportunity.id, opportunity, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Opportunity} opportunity This cannot be a 'ref' to a opportunity
 */
const saveOpportunity = (opportunity: IOpportunity, config: IPagingApiAndNotifierConfig = {}) => {
  if (opportunity.id) {
    return updateOpportunity(opportunity, config);
  } else {
    return createOpportunity(opportunity, config);
  }
};

/**
 * @param {Int} id
 */
const deleteOpportunityById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteOpportunityById,
  getOpportunities,
  getOpportunityById,
  getNewOpportunity,
  createOpportunity,
  updateOpportunity,
  saveOpportunity,
};
