import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityNotes } from "@modernary/mui";

const names = {
  projects: "Projects",
  projectList: "ProjectList",
  projectNew: "ProjectNew",
  projectNewFromOpp: "ProjectNewFromOpp",
  projectEdit: "ProjectEdit",
  projectBase: "ProjectBase",
  projectView: "ProjectView",
  projectNotes: "ProjectNotes",
  projectNoteEdit: "ProjectNoteEdit",
  projectNoteNew: "ProjectNoteNew",
  projectAudit: "ProjectAudit",
};

export { names as projectRouteNames };

const projectRoutes = [
  {
    path: "/projects",
    name: names.projects,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.projectList },
    children: [
      {
        path: "list",
        name: names.projectList,
        meta: { title: "Projects" },
        component: () => import("@/views/project/ProjectList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/project/ProjectBase.vue"),
        redirect: { name: names.projectNew },
        props: true,
        children: [
          {
            path: "",
            name: names.projectNew,
            meta: { title: "New Project", isNew: true },
            component: () => import("@/views/project/ProjectEdit.vue"),
          },
          {
            path: ":clientId(\\d+)?",
            name: names.projectNew,
            meta: { title: "New Project", isNew: true },
            component: () => import("@/views/project/ProjectEdit.vue"),
          },
          {
            path: ":opportunityId(\\d+)?",
            name: names.projectNewFromOpp,
            meta: { title: "New Project", isNew: true },
            component: () => import("@/views/project/ProjectEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.projectBase,
        component: () => import("@/views/project/ProjectBase.vue"),
        redirect: { name: names.projectView },
        children: [
          {
            path: "view",
            name: names.projectView,
            meta: { title: "Project View" },
            component: () => import("@/views/project/ProjectView.vue"),
          },
          {
            path: "edit",
            name: names.projectEdit,
            meta: { title: "Project Edit", isForm: true },
            component: () => import("@/views/project/ProjectEdit.vue"),
          },
          {
            path: "notes",
            name: names.projectNotes,
            meta: { title: "Project Internal Notes" },
            component: {
              render: () => <EntityNotes idField="projectId" routeNamePrefix="ProjectNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.projectNoteEdit,
            meta: { title: "Edit Project Internal Note", isForm: true },
            component: () => import("@/views/project/ProjectNote.vue"),
          },
          {
            path: "notes/new",
            name: names.projectNoteNew,
            meta: { title: "Create Project Internal Note", isForm: true },
            component: () => import("@/views/project/ProjectNote.vue"),
          },
          {
            path: "activity",
            name: names.projectAudit,
            meta: { title: "Project Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Project" />,
            },
          },
        ],
      },
    ],
  },
];
export default projectRoutes;
