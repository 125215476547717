import {
  apiService,
  serializeQueryParams,
  getOtherQueryIdStrings,
  IPagingApiAndNotifierConfig,
  NotifyOptionsSuccess,
  NotifyOptionsError,
} from "@modernary/mui";
import { apiHosts } from "@/api/constants/apiHosts";
import { IAnnotation } from "@/views/annotations/annotations";

const URL = "annotations";
const ENTITY_NAME = "Note";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_KAUAI_API_BASE_URL,
};
const api = apiService(apiHosts.Kauai, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getAnnotations = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.get(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getAnnotationById = (ids: any = {}, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const { id, ...otherIds } = ids;
  const otherIdStrings = getOtherQueryIdStrings(otherIds);

  return api.get(URL + "/" + id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Object} relatedEntityIds Key-value pairs with entity IDs. E.g. { reportId: 123 }
 */
const getNewAnnotation = async (relatedEntityIds: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  const defaultAnnotation = { ...relatedEntityIds };
  return { data: defaultAnnotation };
};

const getAnnotationByIdOrDefault = (ids: any = {}, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  if (!ids.id) {
    return getNewAnnotation(ids);
  } else {
    return getAnnotationById(ids, config);
  }
};

const createAnnotation = (annotation: IAnnotation, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, annotation, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAnnotation = (annotation: IAnnotation, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + annotation.id, annotation, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Annotation} annotation This cannot be a 'ref' to a annotation
 */
const saveAnnotation = (annotation: IAnnotation, config: IPagingApiAndNotifierConfig = {}) => {
  if (annotation.id) {
    return updateAnnotation(annotation, config);
  } else {
    return createAnnotation(annotation, config);
  }
};

/**
 * @param {Int} id
 */
const deleteAnnotationById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const { id, ...otherIds } = ids;
  const otherIdStrings = getOtherQueryIdStrings(otherIds);

  return api.delete(URL + "/" + ids.id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteAnnotationById,
  getAnnotations,
  getAnnotationById,
  getNewAnnotation,
  getAnnotationByIdOrDefault,
  createAnnotation,
  updateAnnotation,
  saveAnnotation,
};
