import { IOpportunity } from "@/views/opportunity/opportunity";
import { addressFromFormkit, addressToFormkit } from "@modernary/mui";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
};

function addAuthRequestFields(entity: IOpportunity) {
  entity._authRequestFields = ["id"];
  return entity;
}
function getInitialData() {
  const result = {} as IOpportunity; //TODO: ts
  return addAuthRequestFields(result);
}

function responseAdapter(response: { data: IOpportunity }) {
  addAuthRequestFields(response.data);
  return response.data;
}

function requestAdapter(data: IOpportunity) {
  return data;
}
