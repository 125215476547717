import { apiService, serializeQueryParams } from "@modernary/mui";
import { apiHosts } from "@/api/constants/apiHosts";
import { enums } from "@/constants/enums";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_KAUAI_API_BASE_URL,
};
const entityAuditApi = apiService(apiHosts.Kauai, axiosParams);

export { entityAuditApi };
